import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getGoogleMapsAddress } from '../../../utils';

import styles from './Address.module.css';

const infoMappingTypes = {
    email: {
        icon: 'e',
        linkPrefix: 'mailto:',
    },
    phone: {
        icon: 't',
        linkPrefix: 'tel:',
    },
    fax: {
        icon: 'f',
        linkPrefix: 'fax:',
    },
};

const ContactInfo = ({ type, value, className }) => {
    const info = infoMappingTypes[type];

    return (
        <a
            className={ styles.link }
            href={ `${info.linkPrefix}${value}` }>
            <p className={ className }>
                <span className={ styles.icon }>{ info.icon }</span>
                { value }
            </p>
        </a>
    );
};

ContactInfo.propTypes = {
    className: PropTypes.string,
    value: PropTypes.node.isRequired,
    type: PropTypes.oneOf(Object.keys(infoMappingTypes)).isRequired,
};

export const Address = ({
    fax,
    lat,
    long,
    text,
    title,
    email,
    phone,
    className,
}) => {
    const addressClasses = classNames(styles.address, className);

    return (
        <div className={ addressClasses }>
            <h3 className={ styles.title }>{ title }</h3>
            <a
                target="_blank"
                rel="noreferrer"
                className={ styles.text }
                href={ getGoogleMapsAddress(lat, long) }>
                { text }
            </a>
            { (phone || fax) && (
                <div className={ styles.numbersWrapper }>
                    { phone && <ContactInfo type="phone" value={ phone } /> }
                    { fax && <ContactInfo type="fax" value={ fax } /> }
                </div>
            ) }
            { email && <ContactInfo type="email" value={ email } /> }
        </div>
    );
};

Address.propTypes = {
    fax: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    className: PropTypes.string,
    lat: PropTypes.number.isRequired,
    long: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default Address;
