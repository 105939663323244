import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Address from '../address';

import styles from './AddressesList.module.css';

export const AddressesList = ({ addresses, className }) => {
    const list = useMemo(() => addresses.map(({
        fax,
        title,
        phone,
        email,
        addressText,
        address: { lat, lon },
    }, index) => (
        <li key={ `address-${index}` }>
            <Address
                lat={ lat }
                fax={ fax }
                long={ lon }
                email={ email }
                phone={ phone }
                title={ title }
                text={ addressText } />
        </li>
    )), [addresses]);

    const addressesListClasses = classNames(styles.list, className);

    return (
        <ul className={ addressesListClasses }>
            { list }
        </ul>
    );
};

AddressesList.propTypes = {
    className: PropTypes.string,
    addresses: PropTypes.arrayOf(
        PropTypes.shape({
            fax: PropTypes.string,
            phone: PropTypes.string,
            email: PropTypes.string,
            title: PropTypes.string.isRequired,
            address: PropTypes.shape({
                lat: PropTypes.number.isRequired,
                lon: PropTypes.number.isRequired,
            }).isRequired,
        }),
    ).isRequired,
};

export default AddressesList;
