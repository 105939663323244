import React from 'react';
import PropTypes from 'prop-types';

import { Layout } from '../../components';
import AddressesList from './addresses-list';
import MapImg from '../../media/images/map.png';

import styles from './Contact.module.css';

export const Contact = ({
    data: {
        contentful: {
            contactPageCollection: {
                items: [page],
            },
        },
    },
    location,
}) => {
    const {
        tabTitle,
        title,
        addressesCollection: {
            items: addresses,
        },
    } = page;

    const seoData = {
        title: `${tabTitle} - PA Advogados`,
    };

    return (
        <Layout
            seoData={ seoData }
            location={ location }
            className={ styles.main }>
            <section className={ styles.contacts }>
                <h1 className={ styles.pageTitle }>{ tabTitle }</h1>
                <div className={ styles.map }>
                    <img
                        tabIndex="-1"
                        src={ MapImg }
                        draggable={ false }
                        className={ styles.mapImg } />
                </div>
                <div className={ styles.info }>
                    <h2 className={ styles.title }>{ title }</h2>
                    <div className={ styles.addressesContainer }>
                        <AddressesList
                            addresses={ addresses }
                            className={ styles.addressesList } />
                    </div>
                </div>
            </section>
        </Layout>
    );
};

Contact.propTypes = {
    data: PropTypes.shape({
        contentful: PropTypes.shape({
            contactPageCollection: PropTypes.shape({
                items: PropTypes.array.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Contact;
